import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "last-seen-hotel-list" }
const _hoisted_2 = {
  key: 0,
  class: "last-seen-hotel-list__wrapper"
}
const _hoisted_3 = { class: "last-seen-hotel-list__heading" }
const _hoisted_4 = { class: "last-seen-hotel-list__listing" }

import LastSeenHotelItem from '@lmt-rpb/LastSeenHotelItem/LastSeenHotelItem.vue';
import SkeletonLoader from '@lmt-rpb/SkeletonLoader/SkeletonLoader.vue';
import { onBeforeMount, ref } from 'vue';
import { LastSeenHotelType } from './types';

interface Props {
	expectedHotelCount: number,
	lastSeenHotelsPromise: Promise<LastSeenHotelType[]>,
	heading?: string,
	showSuns?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'LastSeenHotelList',
  props: {
    expectedHotelCount: {},
    lastSeenHotelsPromise: {},
    heading: { default: 'Zuletzt gesehen' },
    showSuns: { type: Boolean, default: false }
  },
  setup(__props: any) {

const props = __props;

const lastSeenHotels = ref<LastSeenHotelType[] | null>(null);

onBeforeMount(() => {
	props.lastSeenHotelsPromise.then((hotels) => { lastSeenHotels.value = hotels; });
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_ctx.expectedHotelCount && (!lastSeenHotels.value || lastSeenHotels.value.length > 0))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.heading) + ": ", 1 /* TEXT */),
          _createElementVNode("ul", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(lastSeenHotels.value, (hotel) => {
              return (_openBlock(), _createBlock(LastSeenHotelItem, {
                key: hotel.url,
                title: hotel.title,
                image: hotel.image,
                url: hotel.url,
                region: hotel.region,
                "hotel-rating": hotel.hotelRating,
                "show-suns": _ctx.showSuns
              }, null, 8 /* PROPS */, ["title", "image", "url", "region", "hotel-rating", "show-suns"]))
            }), 128 /* KEYED_FRAGMENT */)),
            (!lastSeenHotels.value)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.expectedHotelCount, (index) => {
                  return (_openBlock(), _createElementBlock("li", { key: index }, [
                    _createVNode(SkeletonLoader, {
                      "width-unit": "rem",
                      "loader-width": 35,
                      "loader-height": 11,
                      "border-radius": "1rem",
                      class: "last-seen-hotel-list__item-loading"
                    })
                  ]))
                }), 128 /* KEYED_FRAGMENT */))
              : _createCommentVNode("v-if", true),
            _cache[0] || (_cache[0] = _createElementVNode("li", { class: "last-seen-hotel-list__spacer" }, null, -1 /* HOISTED */))
          ]),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "last-seen-hotel-list__overlay" }, null, -1 /* HOISTED */))
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}
}

})